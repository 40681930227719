import { PureComponent } from 'react';

import { ProductType } from 'Type/ProductList.type';

import './ProductLabels.style';

/**
 * Product Labels
 * @class ProductLabels
 * @namespace Scandipwa/Component/ProductLabels/Component */
export class ProductLabelsComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired
    };

    renderLabelLogic(items) {
        const labelObject = items.reduce((labels, item) => {
            const {
                product: {
                    attributes = []
                } = {}
            } = item;

            const newLabels = { ...labels };

            const todayDate = new Date();
            const specialPrice = attributes.find((attribute) => attribute.attribute_code === 'special_price');
            const specialFromDate = attributes.find((attribute) => attribute.attribute_code === 'special_from_date');
            const specialToDate = attributes.find((attribute) => attribute.attribute_code === 'special_to_date');
            const newsFromDate = attributes.find((attribute) => attribute.attribute_code === 'news_from_date');
            const newsToDate = attributes.find((attribute) => attribute.attribute_code === 'news_to_date');
            const productList = attributes.find((attribute) => attribute.attribute_code === 'list');

            if (specialPrice && specialPrice.attribute_value !== null) {
                // eslint-disable-next-line max-len
                if (specialFromDate && specialFromDate.attribute_value !== null && new Date(specialFromDate.attribute_value) < todayDate
                    // eslint-disable-next-line max-len
                    && specialToDate && specialToDate.attribute_value !== null && new Date(specialToDate.attribute_value) > todayDate) {
                    newLabels.sale = {
                        labelText: __('Sale'),
                        cssClass: 'flag'
                    };
                }
            }

            // eslint-disable-next-line max-len
            if (newsFromDate && newsFromDate.attribute_value !== null && new Date(newsFromDate.attribute_value) < todayDate
                && newsToDate && newsToDate.attribute_value !== null && new Date(newsToDate.attribute_value) > todayDate
                && productList && productList.attribute_value === '453') {
                newLabels.new = {
                    labelText: __('New'),
                    cssClass: 'batch neu'
                };
            } else if (productList && productList.attribute_value === '453') {
                newLabels.best = {
                    labelText: __('Bestseller'),
                    cssClass: 'batch bestseller'
                };
            }

            return newLabels;
        }, { sale: null, new: null, best: null });

        return Object.values(labelObject).filter((label) => label !== null);
    }

    render() {
        const {
            product: {
                items = [],
                type_id,
                attributes = {}
            }
        } = this.props;

        // Dont show if its not a grouped product
        if (type_id !== null && type_id === 'simple') {
            return null;
        }

        const labels = this.renderLabelLogic([{ product: { attributes: Object.values(attributes) } }, ...items]);

        return (
            // eslint-disable-next-line react/jsx-no-comment-textnodes
            <div block="ProductLabels" className="AttributeWrapper">
                { labels.map((label) => (
                    <span block="ProductLabels" elem="Attribute" key={ label.cssClass } className={ label.cssClass }>
                        { label.labelText }
                    </span>
                )) }
            </div>
        );
    }
}

export default ProductLabelsComponent;
